// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-home-v-0-js": () => import("./../../../src/pages/home-v0.js" /* webpackChunkName: "component---src-pages-home-v-0-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indexmock-js": () => import("./../../../src/pages/indexmock.js" /* webpackChunkName: "component---src-pages-indexmock-js" */),
  "component---src-pages-internet-evolution-js": () => import("./../../../src/pages/InternetEvolution.js" /* webpackChunkName: "component---src-pages-internet-evolution-js" */),
  "component---src-pages-internet-evolution-mag-js": () => import("./../../../src/pages/InternetEvolution-mag.js" /* webpackChunkName: "component---src-pages-internet-evolution-mag-js" */),
  "component---src-pages-oldhome-js": () => import("./../../../src/pages/oldhome.js" /* webpackChunkName: "component---src-pages-oldhome-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

